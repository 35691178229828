export const CONTRACT_STATUS = {
	PROBATION: 'probation',
	LIVE: 'live',
	EXPIRED: 'expired',
};

export const CONTRACT_STATUS_COLORS = {
	[CONTRACT_STATUS.PROBATION]: 'yellow',
	[CONTRACT_STATUS.LIVE]: 'green',
	[CONTRACT_STATUS.EXPIRED]: 'grey',
};
