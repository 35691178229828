<template>
  <section id="singgahsini-info">
    <bg-grid>
      <bg-grid-item :col="4">
        <bg-card>
          <div class="mb-16">
            <bg-skeleton v-if="loading" width="150px" height="25px" />
            <div v-else class="flex align-center justify-space-between">
              <bg-text size="heading-5" class="flex align-center">
                Okupansi
                <bg-label-rainbow color="red" class="ml-8">
                  {{ occupancy.occupancy_rate }}
                </bg-label-rainbow>
              </bg-text>
              <router-link :to="roomAllotmentLink">
                <bg-button
                  v-if="accessRoomAllotment && contract.status !== 'expired'"
                  size="sm"
                >
                  Ketersediaan Kamar
                </bg-button>
              </router-link>
            </div>
          </div>

          <bg-divider />

          <div v-if="loading" class="mt-24">
            <bg-list-item v-for="i in 3" :key="i" class="mb-24">
              <bg-skeleton width="60%" height="20px" />
            </bg-list-item>
          </div>
          <div v-else class="mt-24">
            <bg-list-item title="Total Kamar" class="mb-24">
              {{ occupancy.total_rooms }}
            </bg-list-item>
            <bg-list-item title="Tersewa" class="mb-24">
              {{ occupancy.occupied }}
            </bg-list-item>
            <bg-list-item title="Kosong" class="mb-24">
              {{ occupancy.empty }}
            </bg-list-item>
            <bg-list-item title="Out of Order">
              {{ occupancy.out_of_order }}
            </bg-list-item>
          </div>
        </bg-card>
      </bg-grid-item>

      <bg-grid-item :col="4">
        <bg-card>
          <div class="mb-16">
            <bg-skeleton v-if="loading" width="150px" height="25px" />
            <bg-text v-else size="heading-5" class="flex align-center">
              Status Kontrak
              <bg-label-rainbow :color="contractStatusColor" class="ml-8">
                {{ contract.status | capitalize }}
              </bg-label-rainbow>
            </bg-text>
          </div>

          <bg-divider />

          <div v-if="loading" class="mt-24">
            <bg-list-item v-for="i in 3" :key="i" class="mb-24">
              <bg-skeleton width="60%" height="20px" />
            </bg-list-item>
          </div>
          <div v-else class="mt-24">
            <bg-list-item title="Awal Kontrak" class="mb-24">
              {{ contract.start_at | date }}
            </bg-list-item>
            <bg-list-item title="Akhir Kontrak" class="mb-24">
              {{ contract.end_at | date }}
              <bg-label-rainbow
                v-if="isShowLabel(contract.end_at, 60)"
                color="red"
                class="ml-8"
              >
                {{ contract.end_at | fromNow }}
              </bg-label-rainbow>
            </bg-list-item>
            <bg-list-item
              v-if="contract.status === CONTRACT_STATUS.PROBATION"
              title="Akhir Probation"
              class="flex align-center"
            >
              {{ contract.probation_end_at | date }}
              <bg-label-rainbow
                v-if="isShowLabel(contract.probation_end_at, 30)"
                color="red"
                class="ml-8"
              >
                {{ contract.probation_end_at | fromNow }}
              </bg-label-rainbow>
            </bg-list-item>
          </div>
        </bg-card>
      </bg-grid-item>

      <bg-grid-item :col="4">
        <bg-card>
          <div class="mb-16">
            <bg-skeleton v-if="loading" width="150px" height="25px" />
            <div v-else class="flex justify-space-between">
              <bg-text size="heading-5" class="flex align-center">
                Penanggung Jawab
              </bg-text>
              <router-link :to="editPenanggungJawabLink">
                <bg-button size="sm"> Edit </bg-button>
              </router-link>
            </div>
          </div>

          <bg-divider />

          <div v-if="loading" class="mt-24">
            <bg-list-item v-for="i in 3" :key="i" class="mb-24">
              <bg-skeleton width="60%" height="20px" />
            </bg-list-item>
          </div>
          <div v-else class="mt-24">
            <bg-list-item title="BSE" class="mb-24">
              {{ handler.bse_name || '-' }}
            </bg-list-item>
            <bg-list-item title="BD" class="mb-24">
              {{ handler.agent_name || '-' }}
            </bg-list-item>
            <bg-list-item title="AS" class="mb-24">
              {{ handler.account_support_name || '-' }}
            </bg-list-item>
            <bg-list-item title="hospitality">
              {{ handler.supervisor_name || '-' }}
            </bg-list-item>
          </div>
        </bg-card>
      </bg-grid-item>
    </bg-grid>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgCard,
  BgText,
  BgDivider,
  BgLabelRainbow,
  BgListItem,
  BgSkeleton,
  BgButton,
} from 'bangul-vue';
import { capitalize } from 'Utils/typography';
import { dateFormatterToDisplay as format, dayjs } from 'Utils/formatter';
import duration from 'dayjs/plugin/duration';
import { CONTRACT_STATUS, CONTRACT_STATUS_COLORS } from './constants';

dayjs.extend(duration);

const remainingDays = value => {
  if (!value) return -1;

  const valueDate = dayjs(value);
  const today = dayjs();
  const duration = dayjs.duration(valueDate.diff(today)).asDays();
  return Math.ceil(duration);
};

export default {
  name: 'SinggahsiniInfo',

  components: {
    BgText,
    BgGrid,
    BgGridItem,
    BgCard,
    BgDivider,
    BgLabelRainbow,
    BgListItem,
    BgSkeleton,
    BgButton,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    occupancy: {
      type: Object,
      default: () => ({}),
    },
    contract: {
      type: Object,
      default: () => ({}),
    },
    handler: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      CONTRACT_STATUS,
    };
  },

  filters: {
    capitalize: value => (value ? capitalize(value) : value),
    date: value => (value ? format(value, 'DD MMMM YYYY') : '-'),
    fromNow: value => {
      const diff = remainingDays(value);

      if (diff < 0) return 'Expired';
      else if (diff === 0) return 'Hari ini';
      return `${diff} Hari Lagi`;
    },
  },

  computed: {
    accessRoomAllotment() {
      return this.$store.getters.xCheckUserPermission('room-allotment');
    },
    // accessEditPic() {
    //   return this.$store.getters.xCheckUserPermission('edit-pic');
    // },

    contractStatusColor() {
      return (
        CONTRACT_STATUS_COLORS[this.contract.status] ||
        CONTRACT_STATUS_COLORS.expired
      );
    },
    isShowLabel() {
      return (date, interval) =>
        date ? remainingDays(date) <= interval : false;
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
    roomAllotmentLink() {
      return {
        name: 'room-allotment-calendar-view',
        query: {
          property_id: this.propertyId,
        },
      };
    },
    editPenanggungJawabLink() {
      return {
        name: 'edit.person-responsible',
        query: {
          property_id: this.propertyId,
        },
      };
    }
  },
};
</script>
